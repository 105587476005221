import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "davincigold",
  casinoId: 14,
  GA_TRACKING: "G-8WB0PRYJ5E",

  assetsPath: "assets/brands/davincisgold",

  rivalChatGroupName: "DaVinci's Gold",
  prettyName: "DaVinci's Gold",
  contactEmail: "support@davincisgold.com",
  docsEmail: "documents@davincisgold.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/davincisgold/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/davincisgold/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/davincisgold/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/davincisgold/promo.png"}
  ],

  legalData: {
    withdrawalTime: 12,
    minDepositAmount: 5,
    affTerms: true
  },

  //SEO
  metaDescription: "Join the best Online casino, gamble easily at Da Vinci's Gold Casino for luxurious gaming with top slots (slot machine), live dealers, blackjack, bingo slot, and roulette. Real deposti bonuses, VIP rewards, real cash and secure mobile play. Massive payouts fast at a well trusted partner.",
  logoName: "davincis-gold-online-casino-logo.png",
  logoAltText: "Da Vinci's Gold Casino logo in luxurious gold coin design, showcasing a complete online casino, slots, table games, and cryptocurrency support. The Da Vinci's Gold Online Casino offers you security and rewards to have fun gambling online."

};


